<template>
  <v-card>
    <v-card-title class="headline">
      Tracking code
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex sm6>
          <div class="subheading font-weight-bold">How does it work?</div>
          <ol>
            <li>
              Edit "value variable" to the piece of code that contains the order value.
              It could, for example, be
              <code v-text="'<?= $orderValue ?>'"></code>.
            </li>
            <li>Click the tracking code to copy it.</li>
            <li>
              Tracking code is placed in the <code v-text="'<body>'"></code> tag
              on receipt page.
            </li>
          </ol>
        </v-flex>
        <v-flex sm6>
          <v-text-field
            v-model="valueVar"
            label="Value variable"
            hint="The variable that contains the full order value"
            >Value variable</v-text-field
          >
          <br />

          <v-text-field
            label="Tracking code"
            hint="Click the field to copy automatically"
            persistent-hint
            :value="imagePixel"
            readonly
            append-outer-icon="fal fa-copy"
            @click="copyText(imagePixel)"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import copy_clipboard from "@/helpers/mixins/copy_clipboard.js";
export default {
  mixins: [copy_clipboard],
  data: () => ({
    valueVar: ""
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapState("settings", ["settings"]),
    imagePixel() {
      return (
        '<img src="https://' +
        this.settings.app_domain +
        "/p?bid=" +
        this.user.business.id +
        "&value=" +
        this.valueVar +
        '">'
      );
    }
  },
  methods: {}
};
</script>
